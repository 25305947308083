@use "../../scss/variables" as *;

input {
    border: 0;
    background-color: $black;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    border-radius: 3px;
    color: $txt-color;
    font-family: $font-family;
}
